/* unplugin-vue-components disabled */import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import { unref as _unref, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { uuidv4 } from '@/js/utils';
import { getApiBaseUrl } from '@/js/functions';
export default {
  __name: 'TinyMce',
  props: {
    modelValue: String,
    config: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['update:modelValue', 'init'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var refRoot = ref(null);
    var baseUrl = getApiBaseUrl('', 0);
    var cData = reactive({
      content: props.modelValue || ''
    });
    var className = "tiny-mce-".concat(uuidv4());
    var defaultConfig = {
      height: 370,
      language: 'zh_TW',
      selector: ".".concat(className),
      menubar: false,
      automatic_uploads: true,
      resize: 'both',
      plugins: ['advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste wordcount'],
      toolbar: 'code insertfile undo redo fullscreen| styleselect | fontselect fontsizeselect superscript bold underline italic forecolor backcolor table | alignleft aligncenter alignright | bullist numlist  | link image media mediaembed',
      statusbar: true,
      branding: false,
      file_picker_types: 'image',
      content_style: 'body { font-family: Helvetica,Arial,sans-serif; font-size:14px }',
      relative_urls: false,
      convert_urls: false,
      image_dimensions: false,
      // 關掉圖檔上傳時要輸入的寬高欄位

      /* 圖檔上傳功能*/
      file_picker_callback: function file_picker_callback(cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        /*
        Note: In modern browsers input[type="file"] is functional without
        even adding it to the DOM, but that might not be the case in some older
        or quirky browsers like IE, so you might want to add it to the DOM
        just in case, and visually hide it. And do not forget do remove it
        once you do not need it anymore.
        */

        input.onchange = function () {
          var file = this.files[0];
          var formData = new FormData();
          formData.append('file', file);
          formData.append('model_name', 'tinymce');
          $.ajax("".concat(baseUrl, "ajax/uploadPicture"), {
            method: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            success: function success(data) {
              var json = JSON.parse(data);

              if (!Array.isArray(json)) {
                appSwalCustomDialog.alert({
                  title: '提示訊息',
                  text: '發生錯誤，請稍後再試'
                });
                return;
              }

              cb("".concat(baseUrl, "/").concat(json[0]), {
                title: file.name
              });
            },
            error: function error(xhr) {},
            complete: function complete() {}
          });
        };

        input.click();
      },
      setup: function setup(editor) {
        editor.on('change', function () {
          cData.content = editor.getContent();
        });
        emit('init');
      }
    };
    var mergedConfig = computed(function () {
      var obj = Object.assign({}, defaultConfig, props.config);
      return obj;
    });

    function init(event, editor) {
      if (!refRoot.value) return;
      refRoot.value.style.opacity = 1;
      refRoot.value.style.minHeight = refRoot.value.clientHeight + 'px';
      tinymce.init(mergedConfig.value);
    }

    function onChange(evt, editor) {
      console.log('chage', evt, editor);
    }

    watch(function () {
      return cData.content;
    }, function () {
      emit('update:modelValue', cData.content);
    });
    watch(function () {
      return props.modelValue;
    }, function () {
      cData.content = props.modelValue;
    });
    onMounted(function () {
      init();
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "refRoot",
        ref: refRoot,
        class: "tiny-mce",
        style: {
          "opacity": "0"
        }
      }, [_withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _unref(cData).content = $event;
        }),
        class: _normalizeClass([className])
      }, null, 2), [[_vModelText, _unref(cData).content]])], 512);
    };
  }
};