import "core-js/modules/es.string.bold.js";
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-divider", {
      'sty-dotted': $props.dotted,
      'sty-bold': $props.bold,
      'sty-medium': $props.medium,
      'base-divider_theme': $props.theme
    }]),
    style: _normalizeStyle({
      '--divider-color': $props.color ? $props.color : null
    })
  }, null, 6);
}