import { computed, ref, onMounted, reactive } from "vue";
export default {
  components: {},
  props: {
    color: [String],
    bold: Boolean,
    medium: Boolean,
    dotted: Boolean,
    theme: Boolean
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    return {};
  }
};